import React, { FunctionComponent } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

type InfoGridLabelProps = {
    text: string
}

const useStyles = makeStyles(() => {
    return {
        label: {
            fontWeight: 'bold'
        }
    }
})

const InfoGridLabel: FunctionComponent<InfoGridLabelProps> = ({ text }) => {
    const classes = useStyles()
    return (
        <span className={classes.label}>{text}</span>
    )
}

export default InfoGridLabel
