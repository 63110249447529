import React, { FunctionComponent } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => {
    return {
        contentArea: {
            fontWeight: 'normal',
            display: 'grid',
            gridTemplate: 'auto / auto auto',
            gridGap: theme.spacing(1)
        }
    }
})

const InfoGrid: FunctionComponent = ({ children }) => {
    const classes = useStyles()
    return (
        <div className={classes.contentArea}>
            {children}
        </div>
    )
}

export default InfoGrid
