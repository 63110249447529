import React from 'react'
import { createStyles, Theme } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import CardHeader from '@material-ui/core/CardHeader'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { selectPhoto } from '../../../redux/photo/actions'
import { useDispatch } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { Facebook, Instagram } from '@material-ui/icons'
import { IPhotoInfo } from '../../../types'

type PhotoProps = {
    photo: IPhotoInfo
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        },
        title: {
            fontWeight: 200,
            textTransform: 'uppercase'
        },
        media: {
            height: 0,
            paddingTop: '56.25%' // 16:9
        },
        content: {
            flexGrow: 1
        },
        cardActions: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        editButton: {
            marginLeft: theme.spacing(1)
        },
        publishButtonGroup: {
            display: 'flex',
            marginLeft: 'auto'
        }
    })
)

const PhotoThumbnail = ({ photo }: PhotoProps) => {
    const dispatch = useDispatch()
    const handleClick = () => {
        selectPhoto(photo, dispatch)
    }

    const classes = useStyles()
    const title = photo.displayTitle
    return (
        <Grid key={photo.s3Info.eTag} item xs={12} sm={6} md={4}>
            <Card className={classes.root}>
                <CardHeader className={classes.title} title={title} />
                <CardMedia className={classes.media} image={photo.thumbUrl} title={title} />
                <CardActions disableSpacing>
                    <Button variant={'contained'} size="small" color="primary" onClick={handleClick}>
                    View
                    </Button>
                    <Button className={classes.editButton} variant={'outlined'} size="small" color="primary">
                    Edit
                    </Button>
                    <div className={classes.publishButtonGroup}>
                        <IconButton>
                            <Instagram color={'primary'} />
                        </IconButton>
                        <IconButton>
                            <Facebook color={'primary'} />
                        </IconButton>
                    </div>
                </CardActions>
            </Card>
        </Grid>
    )
}

export default PhotoThumbnail
