import CameraIcon from '@material-ui/icons/Camera'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { CloseRounded } from '@material-ui/icons'
import UploadButton from '../Buttons/UploadButton'
import SignOutButton from '../Buttons/SignOutButton'
import Toolbar from '@material-ui/core/Toolbar'
import React from 'react'
import { selectPhoto } from '../../redux/photo/actions'
import { connect, useDispatch } from 'react-redux'
import { PhotoManagerStore } from '../../redux/store'
import { AppBar, createStyles, Theme } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { SelectedPhotoInfo } from '../../types/IPhotoInfo'

type HeaderProps = {
    selectedPhoto: SelectedPhotoInfo
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rightButtons: {
            marginLeft: 'auto'
        }
    })
)

const Header = ({ selectedPhoto }: HeaderProps) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const closeDetailView = () => {
        selectPhoto(null, dispatch)
    }

    const CloseDetailViewButton = () => {
        return (
            <Button onClick={closeDetailView}>
                <CloseRounded color={'secondary'}/>
                <Hidden mdDown>
                    <Typography color={'secondary'}>Close Image</Typography>
                </Hidden>
            </Button>
        )
    }

    return (
        <>
            <AppBar color={'primary'} position={'static'}>
                <Toolbar>
                    <CameraIcon color={'secondary'} />
                    <Hidden mdDown>
                        <Typography variant="h6" color="secondary" noWrap>
                            Photo Manager
                        </Typography>
                    </Hidden>
                    <div className={classes.rightButtons}>
                        {selectedPhoto && <CloseDetailViewButton />}
                        <UploadButton />
                        <SignOutButton />
                    </div>
                </Toolbar>
            </AppBar>
        </>
    )
}

const mapStateToProps = (state: PhotoManagerStore) => {
    return {
        selectedPhoto: state.photos.selected
    }
}

export default connect(mapStateToProps)(Header)
