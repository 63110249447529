import React, { FC } from 'react'
import { IExifInfo } from '../../../types'
import roundTo from 'round-to'
import InfoPanel from '../InfoPanel'
import { PhotoManagerStore } from '../../../redux/store'
import { connect } from 'react-redux'
import InfoGrid from '../../InfoGrid/info-grid'
import InfoGridLabel from '../../InfoGrid/info-grid-label'
import InfoGridValue from '../../InfoGrid/info-grid-value'

type ExifInfoPanelProps = {
    exif: IExifInfo | null
}

const ExifInfoPanel: FC<ExifInfoPanelProps> = ({ exif }) => {
    const panelTitle = 'Exposure Info'

    // don't display anything if there's no EXIF data
    if (!exif) {
        return (
            <InfoPanel title={panelTitle}>
                <p>No exposure information</p>
            </InfoPanel>
        )
    }

    let fStop = Math.sqrt(Math.pow(2, exif.ApertureValue as number))
    fStop = roundTo(fStop, 1)

    let shutterStop = Math.pow(2, exif.ShutterSpeedValue as number)
    shutterStop = Math.round(shutterStop)

    return (
        <InfoPanel title={panelTitle}>
            <InfoGrid>
                <InfoGridLabel text='ISO' />
                <InfoGridValue text={exif.ISO?.toString()} />
                <InfoGridLabel text='Aperture' />
                <InfoGridValue text={fStop ? `f/${fStop}` : undefined} />
                <InfoGridLabel text='Shutter' />
                <InfoGridValue text={shutterStop ? `1/${shutterStop}` : undefined} />
                <InfoGridLabel text='Camera' />
                <InfoGridValue text={`${exif.Make} ${exif.Model}`} />
                <InfoGridLabel text='Lens' />
                <InfoGridValue text={exif.LensModel} />
            </InfoGrid>
        </InfoPanel>
    )
}

const mapStateToProps = (state: PhotoManagerStore) => {
    return {
        exif: state?.photos?.selected?.exifInfo ?? null
    }
}

export default connect(mapStateToProps)(ExifInfoPanel)
