import { applyMiddleware, createStore } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
import appReducer from './reducers'
import { composeWithDevTools } from 'redux-devtools-extension'
import { PhotoStore } from './photo/reducers'
import { UserStore } from './user/reducers'

export type PhotoManagerStore = {
    photos: PhotoStore
    user: UserStore
    filters: any
}

const saveState = (state: PhotoManagerStore) => {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)
    } catch (e) {
        // Ignore write errors;
    }
}

const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state')
        if (serializedState === null) {
            return undefined
        }
        return JSON.parse(serializedState)
    } catch (e) {
        return undefined
    }
}

const configureStore = () => {
    const middlewares = [thunkMiddleware]
    const middlewareEnhancer = applyMiddleware(...middlewares)

    const enhancers = [middlewareEnhancer]
    const composedEnhancers = composeWithDevTools(...enhancers)

    return createStore(appReducer, loadState(), composedEnhancers)
}

export const store = configureStore()
store.subscribe(() => {
    saveState(store.getState() as PhotoManagerStore)
})
