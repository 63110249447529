import React from 'react'
import { createStyles, IconButton, Theme } from '@material-ui/core'
import { PhotoCamera } from '@material-ui/icons'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { uploadPhotos } from '../../../redux/photo/actions'
import { connect, useDispatch } from 'react-redux'
import { PhotoManagerStore } from '../../../redux/store'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1)
            }
        },
        input: {
            display: 'none'
        }
    })
)

type UploadButtonProps = {
    isUploading: boolean
}

const UploadButton = ({ isUploading }: UploadButtonProps) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target
        const fileArray = Array.from<File>(files as FileList)
        uploadPhotos(fileArray, dispatch)
    }

    return (
        <>
            <input accept="image/*" className={classes.input} id="icon-button-file" type="file" onChange={handleInputChange} />
            <label htmlFor="icon-button-file">
                <IconButton color="secondary" aria-label="upload picture button" component="span" >
                    {isUploading ? <CircularProgress color="secondary" /> : <PhotoCamera />}
                </IconButton>
            </label>
        </>
    )
}

const mapStateToProps = (state: PhotoManagerStore) => {
    return {
        isUploading: state.photos.isUploading
    }
}

export default connect(mapStateToProps)(UploadButton)
