import React, { FunctionComponent } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

type InfoGridValueProps = {
    text: string | undefined
}

const useStyles = makeStyles(() => {
    return {
        label: {
        }
    }
})

const InfoGridValue: FunctionComponent<InfoGridValueProps> = ({ text }) => {
    const classes = useStyles()
    return (
        <span className={classes.label}>{text ?? 'N/A'}</span>
    )
}

export default InfoGridValue
