import { PhotoManagerStore } from '../../redux/store'
import { connect, useDispatch } from 'react-redux'
import { SelectedPhotoInfo } from '../../types'
import React, { FC, useEffect } from 'react'
import InfoPanel from './InfoPanel'
import { Typography } from '@material-ui/core'
import { getTags } from '../../redux/photo/actions'

type TagsInfoPanelProps = {
    photo: SelectedPhotoInfo
}

const TagsInfoPanel:FC<TagsInfoPanelProps> = ({ photo }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        getTags(photo?.s3Info.key as string, dispatch)
    }, [])

    if (photo && photo.tags.length > 0) {
        return (
            <InfoPanel title={'Tags'}>
                <Typography>{photo?.tags.join(', ')}</Typography>
            </InfoPanel>
        )
    } else {
        return (
            <InfoPanel title={'Tags'}>
                <Typography>No tags found</Typography>
            </InfoPanel>
        )
    }
}

const mapStateToProps = (state: PhotoManagerStore) => {
    return {
        photo: state.photos.selected
    }
}

export default connect(mapStateToProps)(TagsInfoPanel)
