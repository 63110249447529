import { Dispatch } from 'redux'
import { Auth } from 'aws-amplify'
import { getAllPhotos } from '../photo/actions'

export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS'
export const GET_CURRENT_USER_FAILURE = 'GET_CURRENT_USER_FAILURE'

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const getCurrentUser = (dispatch: Dispatch) => {
    Auth.currentAuthenticatedUser()
        .then(user => {
            getAllPhotos(dispatch)
            dispatch({
                type: GET_CURRENT_USER_SUCCESS,
                payload: user
            })
        })
        .catch(e => dispatch({
            type: GET_CURRENT_USER_FAILURE,
            payload: e
        }))
}
