import PhotoDetail from '../Photos/PhotoDetail'
import PhotoList from '../Photos/PhotoList'
import React from 'react'
import { PhotoManagerStore } from '../../redux/store'
import { connect } from 'react-redux'
import { SelectedPhotoInfo } from '../../types'

type ContentAreaProps = {
    selectedPhoto: SelectedPhotoInfo
}

const ContentArea = ({ selectedPhoto }: ContentAreaProps) => {
    if (selectedPhoto) {
        return <PhotoDetail />
    } else {
        return <PhotoList />
    }
}

const mapStateToProps = (state: PhotoManagerStore) => {
    return {
        selectedPhoto: state.photos.selected
    }
}

export default connect(mapStateToProps)(ContentArea)
