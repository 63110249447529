import React, { FunctionComponent, useEffect } from 'react'
import { Hub } from '@aws-amplify/core'
import Amplify from 'aws-amplify'
import { AmplifyAuthenticator, AmplifySignUp } from '@aws-amplify/ui-react'
import 'react-toastify/dist/ReactToastify.css'
import config from '../../config/aws-config'
import { CssBaseline, Theme } from '@material-ui/core'
import { connect, useDispatch } from 'react-redux'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ContentArea from '../ContentArea'
import ControlBar from '../ControlBar'
import Header from '../Header'
import { ToastContainer } from 'react-toastify'
import { PhotoManagerStore } from '../../redux/store'
import { CurrentUserInfo } from '../../redux/user/reducers'
import { getCurrentUser } from '../../redux/user/actions'

Amplify.configure(config)

interface AppProps {
    user: CurrentUserInfo
}

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            height: '100vh',
            width: '100%',
            display: 'grid',
            gridTemplateRows: 'auto 1fr auto',
            backgroundColor: theme.palette.primary.dark,
            overflow: 'hidden'
        }
    }
})

const App: FunctionComponent<AppProps> = ({ user }) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    // register for auth events
    useEffect(() => {
        Hub.listen('auth', (message) => {
            switch (message.payload.event) {
                case 'signIn':
                    getCurrentUser(dispatch)
                    break
            }
        })
    }, [])

    if (user) {
        return (
            <>
                <CssBaseline />
                <AmplifyAuthenticator>
                    <main className={classes.root}>
                        <Header />
                        <ContentArea />
                        <ControlBar />
                    </main>
                </AmplifyAuthenticator>
                <ToastContainer />
            </>

        )
    } else {
        return (
            <div className={classes.root}>
                <CssBaseline />
                <AmplifyAuthenticator>
                    <AmplifySignUp slot="sign-up"
                        formFields={[
                            { type: 'username' },
                            { type: 'password' },
                            { type: 'email' }
                        ]} />
                </AmplifyAuthenticator>
            </div>
        )
    }
}

const mapStateToProps = (state: PhotoManagerStore) => {
    return {
        user: state.user.currentUser
    }
}

export default connect(mapStateToProps)(App)
