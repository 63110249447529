import React, { FC, HTMLAttributes } from 'react'
import Paper from '@material-ui/core/Paper'
import { createStyles, Theme, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Hidden from '@material-ui/core/Hidden'

type InfoPanelProps = {
    title?: string
    children: JSX.Element | JSX.Element[]
    actionsChildren?: JSX.Element | JSX.Element[]
} & HTMLAttributes<any>

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        outerWrapper: {
            margin: theme.spacing(1),
            minHeight: '6em',
            height: '90%'
        },
        wrapper: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            minWidth: '8em',
            maxWidth: '25em'
        },
        title: {
            color: theme.palette.secondary.contrastText,
            textShadow: `1px 1px ${theme.palette.secondary.dark}`,
            display: 'flex',
            height: '25%',
            width: '100%',
            fontWeight: 300,
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: theme.spacing(0.25),
            backgroundColor: theme.palette.secondary.main,
            padding: '0.5em'
        },
        content: {
            width: '100%',
            height: '100%',
            padding: '0.5em'
        }
    })
)

const InfoPanel: FC<InfoPanelProps> = ({ title, children }) => {
    const classes = useStyles()
    return (
        <Paper variant={'outlined'} elevation={3} className={classes.outerWrapper}>
            <Box className={classes.wrapper}>
                <Hidden mdDown>
                    <Box className={classes.title}>
                        {title && <Typography component={'span'}>{title}</Typography>}
                    </Box>
                </Hidden>
                <Box className={classes.content}>
                    {children}
                </Box>
            </Box>
        </Paper>
    )
}

export default InfoPanel
