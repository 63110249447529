import React, { useCallback } from 'react'
import PhotoThumbnail from '../PhotoThumbnail'
import { Container, createStyles, Theme } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import { PhotoManagerStore } from '../../../redux/store'
import UploadButton from '../../Buttons/UploadButton'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { IPhotoInfo } from '../../../types'

type PhotoListProps = {
    photos: IPhotoInfo[]
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        addPhotos: {

        },
        cardGrid: {
            // height: '100%',
            position: 'relative',
            top: theme.spacing(1.5),
            backgroundColor: theme.palette.primary.dark,
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            overflow: 'hidden scroll'
        }
    })
)

const PhotoList = ({ photos }: PhotoListProps) => {
    const classes = useStyles()
    const renderPhotos = useCallback(() => {
        return photos.map((p: IPhotoInfo) => <PhotoThumbnail key={p.s3Info.eTag} photo={p} />)
    }, [photos])

    if (photos.length === 0) {
        return (
            <Container>
                <Typography>Upload some photos!</Typography>
                <UploadButton />
            </Container>
        )
    } else {
        return (
            <Grid container spacing={3} className={classes.cardGrid}>
                {renderPhotos()}
            </Grid>
        )
    }
}

const mapStateToProps = (state: PhotoManagerStore) => {
    return {
        photos: state.photos.items
    }
}

export default connect(mapStateToProps)(PhotoList)
