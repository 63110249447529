import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { PhotoManagerStore } from '../../../redux/store'
import { SelectedPhotoInfo } from '../../../types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { createStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'

type PhotoDetailProps = {
    photo: SelectedPhotoInfo
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            overflow: 'scroll',
            display: 'grid',
            placeItems: 'start center'
        },
        zoomed: {
            objectFit: 'none'
        },
        normal: {
            maxWidth: '100%',
            maxHeight: '100%'
        }
    })
)

const PhotoDetail = ({ photo }: PhotoDetailProps) => {
    const classes = useStyles()
    const [isZoomed, setIsZoomed] = useState<boolean>(false)
    const [imageStyle, setImageStyle] = useState<string>('photo-detail-img')

    const toggleImageZoom = () => {
        setIsZoomed(!isZoomed)
    }

    useEffect(() => {
        const style = isZoomed ? classes.zoomed : classes.normal
        setImageStyle(style)
    }, [isZoomed])

    // TODO: Replace the alt text with a description generated by the tags
    return (
        <Box className={classes.root}>
            <img className={imageStyle} src={photo?.fullUrl} alt={photo?.fullUrl} onClick={toggleImageZoom} />
        </Box>
    )
}

const mapStateToProps = (state: PhotoManagerStore) => {
    return {
        photo: state.photos.selected
    }
}

export default connect(mapStateToProps)(PhotoDetail)
