import { Storage } from 'aws-amplify'

export const saveFileToS3 = async (file: File, usePrivateStorage = true): Promise<void> => {
    const uploadFunction = usePrivateStorage ? Storage.vault.put : Storage.put

    await uploadFunction(`full/${file.name}`, file, {
        contentType: file.type
    })
}

export const saveFilesToS3 = async (files: File[], usePrivateStorage = true): Promise<void> => {
    const promises = files.map<Promise<void>>(async (file) => await saveFileToS3(file, usePrivateStorage))
    await Promise.all(promises)
}
