import { Dispatch } from 'redux'
import { saveFilesToS3 } from '../../helpers/AwsHelpers'
import { toast } from 'react-toastify'
import { SelectedPhotoInfo } from '../../types'
import { getTagsForPhoto, getUserPhotos } from '../../helpers/PhotoFunctions'

export const GET_ALL_PHOTOS_START = 'GET_ALL_PHOTOS_START'
export const GET_ALL_PHOTOS_SUCCESS = 'GET_ALL_PHOTOS_SUCCESS'
export const GET_ALL_PHOTOS_FAILURE = 'GET_ALL_PHOTOS_FAILURE'

export const UPLOAD_PHOTOS_START = 'UPLOAD_PHOTOS_START'
export const UPLOAD_PHOTOS_SUCCESS = 'UPLOAD_PHOTOS_SUCCESS'
export const UPLOAD_PHOTOS_FAILURE = 'UPLOAD_PHOTOS_FAILURE'

export const GET_PHOTO_TAGS_START = 'GET_PHOTO_TAGS_START'
export const GET_PHOTO_TAGS_SUCCESS = 'GET_PHOTO_TAGS_SUCCESS'
export const GET_PHOTO_TAGS_FAILURE = 'GET_PHOTO_TAGS_FAILURE'

export const SELECTED_PHOTO_SET = 'SELECTED_PHOTO_SET'
export const SELECTED_PHOTO_CLEAR = 'SELECTED_PHOTO_CLEAR'

/**
 * Retrieves the basic photo information for all photos in the S3 store
 * @param dispatch
 */
export const getAllPhotos = (dispatch: Dispatch) => {
    dispatch({
        type: GET_ALL_PHOTOS_START
    })

    getUserPhotos()
        .then(result => {
            dispatch({
                type: GET_ALL_PHOTOS_SUCCESS,
                payload: result
            })
        })
        .catch(e => {
            dispatch({
                type: GET_ALL_PHOTOS_FAILURE,
                payload: e
            })
        })
}

/**
 * Fetches the EXIF information for a photo
 * @param key The S3 key used to look up the record in Dynamo
 * @param dispatch The dispatch function to use
 */
export const getExifInfo = (key: string, dispatch: Dispatch) => {
    return null
}

/**
 * Fetches the generated tags for a photo
 * @param key The S3 key used to look up the record in Dynamo
 * @param dispatch The dispatch function to use
 */
export const getTags = (key: string, dispatch: Dispatch) => {
    dispatch({
        type: GET_PHOTO_TAGS_START
    })

    getTagsForPhoto(key)
        .then((tags) => {
            dispatch({
                type: GET_PHOTO_TAGS_SUCCESS,
                payload: { key, tags }
            })
        })
        .catch(e => {
            dispatch({
                type: GET_PHOTO_TAGS_FAILURE,
                payload: e
            })
        })
}

export const uploadPhotos = (photos: File[], dispatch: Dispatch) => {
    dispatch({
        type: UPLOAD_PHOTOS_START,
        payload: photos
    })

    saveFilesToS3(photos)
        .then(() => {
            toast.success('Upload complete')
            setTimeout(getAllPhotos, 5000, dispatch)
            dispatch({
                type: UPLOAD_PHOTOS_SUCCESS
            })
        })
        .catch(e => {
            toast.error('Upload failed')
            dispatch({
                type: UPLOAD_PHOTOS_FAILURE,
                payload: e
            })
        })
}

export const selectPhoto = (photo: SelectedPhotoInfo, dispatch: Dispatch) => {
    if (photo) {
        dispatch({
            type: SELECTED_PHOTO_SET,
            payload: photo
        })
    } else {
        dispatch({
            type: SELECTED_PHOTO_CLEAR
        })
    }
}
