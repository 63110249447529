import { AnyAction } from 'redux'
import {
    GET_ALL_PHOTOS_FAILURE,
    GET_ALL_PHOTOS_START,
    GET_ALL_PHOTOS_SUCCESS, GET_PHOTO_TAGS_SUCCESS,
    SELECTED_PHOTO_CLEAR,
    SELECTED_PHOTO_SET,
    UPLOAD_PHOTOS_FAILURE,
    UPLOAD_PHOTOS_START,
    UPLOAD_PHOTOS_SUCCESS
} from './actions'
import { LOGOUT_SUCCESS } from '../user/actions'
import { IPhotoInfo, SelectedPhotoInfo } from '../../types'

export type PhotoStore = {
    items: IPhotoInfo[],
    isRefreshing: boolean,
    isUploading: boolean,
    selected: SelectedPhotoInfo
}

const initialState: PhotoStore = {
    items: [],
    isRefreshing: false,
    isUploading: false,
    selected: null
}

export const photos = (state: PhotoStore = initialState, action: AnyAction) => {
    switch (action.type) {
        case GET_ALL_PHOTOS_START:
            return { ...state, isRefreshing: true }
        case GET_ALL_PHOTOS_SUCCESS:
            return { ...state, items: action.payload, isRefreshing: false }
        case GET_ALL_PHOTOS_FAILURE:
            return { ...state, isRefreshing: false }

        case GET_PHOTO_TAGS_SUCCESS: {
            const photos = state.items
            const index = photos.findIndex(p => p.s3Info.key === action.payload.key)
            if (index >= 0) {
                photos[index].tags = action.payload.tags
            }

            return { ...state, items: photos }
        }

        case UPLOAD_PHOTOS_START:
            return { ...state, isUploading: true }
        case UPLOAD_PHOTOS_SUCCESS:
        case UPLOAD_PHOTOS_FAILURE:
            return { ...state, isUploading: false }

        case SELECTED_PHOTO_CLEAR:
            return { ...state, selected: null }
        case SELECTED_PHOTO_SET:
            return { ...state, selected: action.payload }

        case LOGOUT_SUCCESS:
            return { ...state, items: [], selected: null }

        default:
            return state
    }
}
