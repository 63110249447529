import React from 'react'
import { ExitToAppRounded } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import { Auth } from 'aws-amplify'
import { LOGOUT_FAILURE, LOGOUT_SUCCESS } from '../../../redux/user/actions'
import { useDispatch } from 'react-redux'

const SignOutButton = () => {
    const dispatch = useDispatch()
    const handleClick = () => {
        Auth.signOut()
            .then(user => dispatch({
                type: LOGOUT_SUCCESS,
                payload: user
            }))
            .catch(e => dispatch({
                type: LOGOUT_FAILURE,
                payload: e
            }))
    }

    return (
        <IconButton color="secondary" aria-label="upload picture button" component="span" onClick={handleClick}>
            <ExitToAppRounded />
        </IconButton>
    )
}

export default SignOutButton
