import { combineReducers } from 'redux'
import { photos } from './photo/reducers'
import { user } from './user/reducers'

const appReducer = combineReducers({
    photos,
    user
})

export default appReducer
