import React from 'react'
import ExifInfoPanel from '../Panels/ExifInfoPanel'
import { PhotoManagerStore } from '../../redux/store'
import StorageStats from '../Panels/StorageStatsPanel'
import { connect } from 'react-redux'
import { AppBar, createStyles, Theme } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Toolbar from '@material-ui/core/Toolbar'
import { SelectedPhotoInfo } from '../../types/IPhotoInfo'
import TagsInfoPanel from '../Panels/TagsInfoPanel'

type ControlBarProps = {
    selectedPhoto: SelectedPhotoInfo
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            flexDirection: 'row',
            bottom: 0,
            justifyContent: 'flex-start',
            backgroundColor: theme.palette.primary.main
        }
    })
)

const ControlBar = ({ selectedPhoto }: ControlBarProps) => {
    const classes = useStyles()
    return (
        <AppBar className={classes.appBar} position={'static'}>
            <Toolbar>
                <StorageStats />
                { selectedPhoto && <ExifInfoPanel />}
                { selectedPhoto && <TagsInfoPanel />}
            </Toolbar>
        </AppBar>
    )
}

const mapStateToProps = (state: PhotoManagerStore) => {
    return {
        selectedPhoto: state.photos.selected
    }
}

export default connect(mapStateToProps)(ControlBar)
