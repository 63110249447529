import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App/App'
import reportWebVitals from './reportWebVitals'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import { ThemeProvider } from '@material-ui/core/styles'
import { Provider } from 'react-redux'
import { store } from './redux/store'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#8CB5DB'
        },
        secondary: {
            main: '#FFCE8F'
        }
    }
})

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <App />
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
