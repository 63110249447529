import { API, Auth, Storage } from 'aws-amplify'
import { IPhotoInfo } from '../types'
import { IExifInfo } from '../types/IExifInfo'

const getApiValue = async <T>(key: string, endpoint: string): Promise<T> => {
    const userinfo = await Auth.currentUserInfo()
    const response = await API.get('photoManagerApi', endpoint, {
        queryStringParameters: { key: encodeURI(`private/${userinfo.id}/${key}`) }
    })

    return response as T
}

const getPhotoExif = async (key: string): Promise<object | undefined> => {
    const response = getApiValue<IExifInfo>(key, '/photos/exif')

    if (!response) {
        return undefined
    }

    return response
}

/**
 * Formats an S3 key for use as a display title
 * @param title The key to format
 */
export const cleanPhotoTitle = (title: string): string => {
    // strip the thumbs directory name
    let cleaned = title.replace('thumbs/', '')

    cleaned = cleaned.replace('full/', '')

    // remove the file extension
    cleaned = cleaned.split('.')[0]

    // replace underscores and dashes with spaces
    cleaned = cleaned.replace(/[_-]/g, ' ')

    return cleaned
}

/**
 * Retrieves all the images, with thumbnail links, for the currently logged in user
 */
export const getUserPhotos = async () => {
    const s3Objects = await Storage.vault.list('')

    const photos: IPhotoInfo[] = []
    await Promise.all(s3Objects.map(async (s3File: any) => {
        // ignore thumbnails; this link is generated from the main image
        if (s3File.key.includes('thumbs/')) { return }

        const filename = s3File.key.replace(/^full\//gi, '')
        const exif = await getPhotoExif(s3File.key)

        const url = await Storage.vault.get(s3File.key)
        const thumbUrl = await Storage.vault.get(`thumbs/${filename}`)
        photos.push({
            displayTitle: cleanPhotoTitle(s3File.key),
            fullUrl: url as string,
            s3Info: {
                key: s3File.key,
                lastUpdated: s3File.lastModified,
                fileSize: s3File.size,
                eTag: s3File.eTag
            },
            thumbUrl: thumbUrl as string,
            exifInfo: exif,
            tags: []
        })
    }))
    return photos
}

type TagInfo = {
    Instances: any[]
    Confidence: number
    Name: string
}
export const getTagsForPhoto = async (key: string): Promise<string[]> => {
    const tags: string[] = []
    const response = await getApiValue<TagInfo[]>(key, '/photos/tags')
    if (response) {
        response.map((tagInfo) => tags.push(tagInfo.Name))
    }

    return tags
}
