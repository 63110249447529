import { Auth } from 'aws-amplify'

const config = {
    Auth: {
        mandatorySignIn: true,
        userPoolId: 'us-east-2_ajzYbm1mv',
        userPoolWebClientId: '2bqm7u749rh5qp8fe3qbtat8gh',
        identityPoolId: 'us-east-2:7a12b964-035c-47f3-a8ba-680a7ab46706',
        region: 'us-east-2'
    },
    API: {
        endpoints: [
            {
                name: 'photoManagerApi',
                endpoint: 'https://lv14uv0yo6.execute-api.us-east-2.amazonaws.com/dev',
                custom_header: async () => {
                    return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                }
            }
        ]
    },
    Storage: {
        AWSS3: {
            bucket: 'photo-manager-api-dev-photo-bucket',
            region: 'us-east-2'
        }
    }
}

export default config
