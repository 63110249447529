import fileSize from 'filesize'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { PhotoManagerStore } from '../../../redux/store'
import InfoPanel from '../InfoPanel'
import { IPhotoInfo } from '../../../types'
import InfoGrid from '../../InfoGrid/info-grid'
import InfoGridValue from '../../InfoGrid/info-grid-value'
import InfoGridLabel from '../../InfoGrid/info-grid-label'

type StorageStatsProps = {
    photos: IPhotoInfo[]
}

const StorageStats = ({ photos }: StorageStatsProps) => {
    const [sizeOnDisk, setSizeOnDisk] = useState<number>(0)
    const [avgPhotoSize, setAvgPhotoSize] = useState<number>(0)

    useEffect(() => {
        let total = 0
        photos.forEach(photo => { total += photo.s3Info.fileSize })
        setSizeOnDisk(total)
    }, [photos])

    useEffect(() => {
        if (photos.length) {
            setAvgPhotoSize(sizeOnDisk / photos.length)
        }
    }, [photos, sizeOnDisk])

    return (
        <InfoPanel title={'Storage Info'}>
            <InfoGrid>
                <InfoGridLabel text={'Total Size'} />
                <InfoGridValue text={fileSize(sizeOnDisk)} />
                <InfoGridLabel text={'Photo Count'} />
                <InfoGridValue text={photos.length.toString()} />
                <InfoGridLabel text={'Avg. Size'} />
                <InfoGridValue text={fileSize(avgPhotoSize)} />
            </InfoGrid>
        </InfoPanel>
    )
}

const mapStateToProps = (state: PhotoManagerStore) => {
    return {
        photos: state.photos.items
    }
}

export default connect(mapStateToProps)(StorageStats)
