import { UserInfo } from '../../types/UserInfo'
import { AnyAction } from 'redux'
import { GET_CURRENT_USER_FAILURE, GET_CURRENT_USER_SUCCESS, LOGOUT_FAILURE, LOGOUT_SUCCESS } from './actions'

export type CurrentUserInfo = UserInfo | null

export type UserStore = {
    currentUser: CurrentUserInfo
}

const initialState: UserStore = {
    currentUser: null
}

export const user = (state: UserStore = initialState, action: AnyAction) => {
    switch (action.type) {
        case GET_CURRENT_USER_SUCCESS:
            return { ...state, currentUser: action.payload }
        case GET_CURRENT_USER_FAILURE:
            return { ...state, currentUser: null }

        case LOGOUT_SUCCESS:
            return { ...state, currentUser: null }
        case LOGOUT_FAILURE:
            return { ...state }

        default:
            return state
    }
}
